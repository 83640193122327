import React, { useEffect, useState } from 'react';
import BackgroundImg from './images/video-img.jpg';
import Video from './images/video-background.mp4';
import moment from 'moment';

const AnnoucementBar = () => {
  const [apy, setAPY] = useState(null);
  const [recentPayouts, setRecentPayouts] = useState([]);
  const apiUrl = 'https://avalanche.cash/api/apy/XEC';

  const numberWithCommas = (numberString) => {
    const number = parseFloat(numberString.replace(/,/g, ''));
    if (!isNaN(number)) {
      return number.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return 'Invalid number';
    }
  };

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAPY((data.apy * 100).toFixed(2));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    fetch('https://avalanche.cash/api/recentstakingpayouts', {})
      .then((res) => res.json())
      .then((response) => {
        setRecentPayouts(response);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      <div
        className="countdown-ctn"
        style={{ backgroundImage: 'url(' + BackgroundImg + ')' }}
      >
        <video
          playsInline
          autoPlay
          muted
          loop
          poster={BackgroundImg}
          id="ecash_background"
        >
          <source src={Video} type="video/mp4" />
        </video>
        <div className="overlay" />
        <div className="gradient-transition" />
        <div className="countdown_outer_ctn">
          <div>
            <div
              className="countdown_text_ctn animate__animated animate__bounceInDown"
              style={{ marginTop: '0' }}
            >
              {apy && (
                <h3 className="percentage">
                  <span>{apy}%</span> APY
                </h3>
              )}
              <h3>Staking Rewards</h3>
              <h4>Now live on eCash!</h4>
              <a
                href="https://e.cash/upgrade"
                target="_blank"
                rel="noreferrer"
                className="countdown_link"
              >
                Learn more
              </a>
            </div>
            <h4 className="latest_rewards_title animate__animated animate__bounceInDown">
              Latest Staking Rewards
            </h4>
            <div className="latestrewards_ctn animate__animated animate__bounceInDown">
              {recentPayouts.length > 0 &&
                recentPayouts.slice(0, 3).map((item, index) => (
                  <div className="reward_ctn">
                    <div className="amount">
                      {numberWithCommas(item.amount)} XEC
                    </div>
                    <div className="paid_to">Paid to</div>
                    <div className="peername">
                      <b>{item.peerName}</b>
                    </div>
                    <div className="paid_to">
                      {moment.unix(item.timeStamp).fromNow()}
                    </div>
                  </div>
                ))}
            </div>
            {/* <div
              className="countdown_link animate__animated animate__bounceInDown"
              onClick={() => setLeaderboard(true)}
            >
              Leaderboard
            </div> */}
          </div>
        </div>
      </div>
      {/* {leaderboard && (
        <LeaderBoard
          recentPayouts={recentPayouts}
          setLeaderboard={setLeaderboard}
        />
      )} */}
    </>
  );
};

export default AnnoucementBar;
