import React, { useState, useEffect } from 'react';
import 'animate.css';
import SiteLinks from './SiteLinks/SiteLinks.js';
import Logo from './images/ecash-logo-horizontal.png';
import Hand from './images/hand.png';
import Dots from './images/dots.png';
import GreenBlock from './images/green-cube.png';
import PurpleBlock from './images/purple-cube.png';
import Arrow from './images/purple-arrow.png';
import { ReactComponent as Wrench } from './images/wrench-icon.svg';
import { ReactComponent as Rocket } from './images/rocket-icon.svg';
import { ReactComponent as Incubating } from './images/incubating-icon.svg';
import Network from './Network.js';
import Roadmap from './Roadmap.js';
import AnnoucementBar from './AnnoucementBar.js';

const strapiUrl = 'https://api.avalanche.cash';

const App = () => {
  const [modal, setModal] = useState({ show: false });
  const [icondata, setIconData] = useState([]);
  const [roadmapdata, setRoadmapData] = useState([]);
  const [futureroadmapdata, setFutureRoadmapData] = useState([]);

  useEffect(() => {
    fetch(`${strapiUrl}/api/icons?populate=*`, {})
      .then((res) => res.json())
      .then((response) => {
        setIconData(response.data);
      })
      .catch((error) => console.log(error));

    /////////////////////////////////////////////// BLOCKS

    fetch(`${strapiUrl}/api/roadmaps?populate=*`, {})
      .then((res) => res.json())
      .then((response) => {
        setRoadmapData(response.data);
        if (window.location.hash) {
          var el = document.getElementById(window.location.hash.substring(1));
          setTimeout(() => {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }, 200);
        }
      })
      .catch((error) => console.log(error));

    /////////////////////////////////////////////// FUTURE

    fetch(`${strapiUrl}/api/future-roadmap-items?populate=*`, {})
      .then((res) => res.json())
      .then((response) => {
        setFutureRoadmapData(response.data);
      })
      .catch((error) => console.log(error));

    /////////////////////////////////////////////// COUNTDOWN

    // fetch('https://api.avalanche.cash/countdown', {})
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setCountDown(response.show);
    //   })
    //   .catch((error) => console.log(error));
  }, []);

  // useEffect(() => {
  //   if (isMounted.current && Date.now() > ACTIVATION_TIMESTAMP) {
  //     updateStatus();
  //   } else {
  //     isMounted.current = true;
  //   }
  // }, [icondata]);

  // const updateStatus = () => {
  //   setTimer(true);
  //   let myArray = icondata;
  //   let myArray2 = roadmapdata;
  //   let objIndex = myArray.findIndex((obj) => obj.id === 2);
  //   let objIndex2 = myArray.findIndex((obj) => obj.id === 3);
  //   let objIndex3 = myArray2.findIndex((obj) => obj.id === 6);
  //   myArray[objIndex].status = 'Live';
  //   myArray[objIndex2].status = 'Live';
  //   myArray2[objIndex3].completed_date = 'Complete';
  //   setModal(false);
  //   window.scrollTo(0, 0);
  // };

  return (
    <div>
      {/************************ COUNT DOWN SECTION *********************/}

      <AnnoucementBar />
      <div className="block">
        <div className="container" style={{ position: 'relative' }}>
          <div className="header">
            <a href="https://e.cash" target="_blank" rel="noreferrer">
              <img
                src={Logo}
                alt="ecash logo"
                className="animate__animated animate__fadeInUp"
                style={{ animationDelay: '.4s' }}
              />
            </a>
          </div>
        </div>
      </div>

      {/***************** HERO SECTION *****************/}

      <div className="block">
        <div className="container">
          <div className="hero">
            <div className="col-1 animate__animated animate__fadeInUp">
              <h1 className="glitch" data-text="AVALANCHE">
                AVALANCHE
              </h1>
              <br />
              {/* {timer ? (
                <h4
                  className="animate__animated animate__fadeOutUp"
                  style={{ position: 'absolute', animationDelay: '200ms' }}
                >
                  Coming soon to eCash!
                </h4>
              ) : (
                <h4>Coming soon to eCash!</h4>
              )} */}

              {/* <h4>Coming soon to eCash!</h4> */}

              <p>
                Avalanche is a revolutionary consensus algorithm that brings
                rock solid security, lightning fast speed, and dynamic
                capabilities to the eCash protocol.
              </p>
            </div>
            <div
              className="col-2 animate__animated animate__fadeInUp"
              style={{ animationDelay: '.2s' }}
            >
              <img src={Hand} alt="ecash hand" />
            </div>
          </div>
        </div>
        <div
          className="hero-background"
          style={{ backgroundImage: `url(${Dots})` }}
        ></div>
      </div>

      <Network />

      {/************************ ICONS SECTION *********************/}

      <div className="block">
        <div className="gradient" />
        <div className="container">
          <div
            className="icons-ctn animate__animated animate__fadeInUp"
            style={{ animationDelay: '.5s' }}
          >
            {icondata
              .sort((a, b) =>
                a.attributes.order > b.attributes.order ? 1 : -1
              )
              .map((item, index) => (
                <div className="icon-col-ctn" key={index}>
                  <div
                    className="icon-col"
                    onClick={() =>
                      setModal({
                        show: true,
                        title: item.attributes.title,
                        description: item.attributes.description,
                        subtitle: item.attributes.subtitle,
                        status: item.attributes.status,
                      })
                    }
                  >
                    <img
                      src={
                        strapiUrl + item.attributes.image.data[0].attributes.url
                      }
                      alt={item.attributes.title}
                    />
                    <h4>{item.attributes.title}</h4>
                    <div className="icon-status">
                      {item.attributes.status === 'Live' ? (
                        <div className="icon-status-dot" />
                      ) : item.attributes.status === 'Coming_soon' ? (
                        <Wrench />
                      ) : item.attributes.status === 'Incubating' ? (
                        <Incubating style={{ fill: '#fca600' }} />
                      ) : (
                        <Rocket style={{ fill: '#0074c2' }} />
                      )}
                      <div>
                        {item.attributes.status === 'Coming_soon'
                          ? 'Coming Soon'
                          : item.attributes.status}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {modal.show && (
        <div>
          <div
            className="modal-backdrop animate__animated animate__fadeIn"
            style={{
              width: '100vw',
              height: '100vh',
            }}
            onClick={() => setModal({ show: false })}
          >
            <div
              className="modal-border animate__animated animate__slideInUp"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-inner">
                <div
                  className="close-btn"
                  onClick={() => setModal({ show: false })}
                />
                <h3>{modal.title}</h3>
                <h5>{modal.subtitle}</h5>
                {modal.description.map((item) => (
                  <p key={item}>{item}</p>
                ))}
                <div className="modal-status">
                  {modal.status === 'Live' ? (
                    <div className="modal-status-dot" />
                  ) : modal.status === 'Coming_soon' ? (
                    <Wrench />
                  ) : modal.status === 'Incubating' ? (
                    <Incubating style={{ fill: '#fca600' }} />
                  ) : (
                    <Rocket style={{ fill: '#0074c2' }} />
                  )}
                  <div className="modal-status-text">
                    {modal.status === 'Coming_soon'
                      ? 'Coming Soon - feature is coming as part of the current milestone'
                      : modal.status}
                    {modal.status === 'Incubating' &&
                      ' - feature is complete and being set up for public release'}
                    {modal.status === 'Future' &&
                      ' - feature is coming as part of a later milestone'}
                    {modal.status === 'Live' &&
                      ' - feature is complete and active on the mainnet'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/************************ ROADMAP TOP SECTION *********************/}

      <div
        className="block animate__animated animate__fadeInUp"
        style={{ animationDelay: '.8s' }}
      >
        <div className="container">
          <div className="top-text-ctn">
            <h2>Avalanche Roadmap</h2>
            <p>
              Bitcoin ABC is hard at work bringing Avalanche to eCash. Below is
              a breakdown of the progress so far, as well as what’s still to
              come.
            </p>
            <h3>At a Glance</h3>
          </div>
          <div className="mini-map-ctn">
            {roadmapdata.map(
              (item, index) =>
                item.attributes.completed_date && (
                  <>
                    <div className="minimap-block-ctn" key={index}>
                      <a href={'#milestone' + (index + 1)}>
                        <img
                          className="minimap-block"
                          src={GreenBlock}
                          alt="green block"
                          style={
                            item.attributes.completed_date === 'Incubating'
                              ? {
                                  filter: 'hue-rotate(-104deg) saturate(0.84)',
                                }
                              : null
                          }
                        />
                      </a>
                      <div className="milestone-helper">
                        <div
                          className="arrow-up"
                          style={
                            item.attributes.completed_date === 'Incubating'
                              ? {
                                  filter: 'hue-rotate(-104deg) saturate(0.84)',
                                }
                              : null
                          }
                        />
                        <br />
                        <span style={{ fontWeight: '700' }}>
                          Milestone {index + 1}
                        </span>
                        <br />
                        {item.attributes.title}
                      </div>
                    </div>
                    {item.attributes.sub_items.map(
                      (sub_item, index) =>
                        sub_item.complete && (
                          <div key={index} className="minimap-dot" />
                        )
                    )}
                  </>
                )
            )}
            <div className="here-line">
              <div className="here-line-text">
                <h6>We are here</h6>
              </div>
            </div>
            {roadmapdata.map(
              (item) =>
                item.attributes.completed_date && (
                  <>
                    {item.attributes.sub_items.map(
                      (sub_item, index) =>
                        !sub_item.complete && (
                          <div
                            key={index}
                            className="minimap-dot"
                            style={{ backgroundColor: '#510f6d' }}
                          />
                        )
                    )}
                  </>
                )
            )}
            {roadmapdata.map(
              (item, index) =>
                !item.attributes.completed_date && (
                  <>
                    <div className="minimap-block-ctn" key={index}>
                      <a href={'#milestone' + (index + 1)}>
                        <img
                          className="minimap-block"
                          src={PurpleBlock}
                          alt="purple block"
                        />
                      </a>
                      <div className="milestone-helper">
                        <div
                          className="arrow-up"
                          style={{ borderBottomColor: '#9700bf' }}
                        />
                        <br />
                        <span style={{ fontWeight: '700' }}>
                          Milestone {index + 1}
                        </span>
                        <br />
                        {item.attributes.title}
                      </div>
                    </div>
                    {item.attributes.sub_items &&
                      item.attributes.sub_items.map((sub_item, index) => (
                        <div
                          key={index}
                          className="minimap-dot"
                          style={
                            sub_item.complete
                              ? null
                              : { backgroundColor: '#510f6d' }
                          }
                        />
                      ))}
                  </>
                )
            )}
            <img className="purple-arrow" src={Arrow} alt="arrow" />
          </div>
        </div>
      </div>

      {/************************ ROADMAP *********************/}

      <div className="block">
        <div className="container">
          <div
            className="roadmap-ctn animate__animated animate__fadeIn"
            style={{ animationDelay: '1.2s' }}
          >
            <h3>Detailed Breakdown</h3>
            <div className="roadmap-inner-ctn">
              <Roadmap roadmapdata={roadmapdata} />
            </div>
          </div>
        </div>
      </div>

      {/************************ ROADMAP TOP SECTION *********************/}

      <div
        className="block animate__animated animate__fadeInUp"
        style={{ animationDelay: '1s' }}
      >
        <div className="container">
          <div className="future-ctn">
            <h3>Future Roadmap</h3>
            <p>
              After the base technology of Avalanche is fully implemented we can
              start looking at these great features.
            </p>

            <div className="future-blocks-ctn">
              {futureroadmapdata.map((item, index) => (
                <div className="future-block" key={index}>
                  <div className="future-block-inner">
                    {item.attributes.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/************************ Whitepaper SECTION *********************/}

      <div
        className="block animate__animated animate__fadeInUp"
        style={{ animationDelay: '1s' }}
      >
        <div className="container">
          <div className="future-ctn" style={{ marginTop: '50px' }}>
            <h3>Read the Avalanche Whitepaper</h3>
            <p>
              For an in depth overview of the Avalanche protocol, check out the
              whitepaper.
            </p>

            <a
              href="https://ipfs.io/ipfs/QmUy4jh5mGNZvLkjies1RWM4YuvJh5o2FYopNPVYwrRVGV"
              target="_blank"
              rel="noreferrer"
              className="learn-btn"
            >
              <div>Avalanche Whitepaper</div>
            </a>
          </div>
        </div>
      </div>

      {/************************ LEARN MORE SECTION *********************/}

      <div
        className="block animate__animated animate__fadeInUp"
        style={{ animationDelay: '1s' }}
      >
        <div className="container">
          <div className="future-ctn" style={{ marginTop: '50px' }}>
            <h3>Learn More about eCash</h3>
            <p>
              There is more to eCash than just Avalanche. Visit e.cash to learn
              more.
            </p>

            <a
              href="https://e.cash"
              target="_blank"
              rel="noreferrer"
              className="learn-btn"
            >
              <div>Learn More</div>
            </a>
          </div>
        </div>
      </div>

      {/************************ SITE LINKS SECTION *********************/}

      <SiteLinks />
    </div>
  );
};

export default App;
