import React, { useState, useEffect, useRef } from 'react';
import { ForceGraph3D } from 'react-force-graph';
import EcashLogo from './images/ecash-circle.png';
import data from './testdata.json';
import * as THREE from 'three';
// import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

const Network = () => {
  const [selectedNode, setSelectedNode] = useState(null);
  // const extraRenderers = [new CSS2DRenderer()];
  const fgRef = useRef();
  const [stopEngine, setStopEngine] = useState(false);
  const [networkdata, setNetworkData] = useState(data);
  const [networkloading, setNetworkLoading] = useState(true);
  const [networktotals, setNetworkTotals] = useState({
    totalStake: '...',
    nodeCount: '...',
    proofCount: '...',
  });

  const getDays = (timestamp) => {
    var date1 = new Date();
    var date2 = new Date(timestamp * 1000);
    var delta = Math.abs(date1 - date2) / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    // var seconds = delta % 60;  // in theory the modulus is not required
    return days + ' Days, ' + hours + ' Hours, ' + minutes + ' Minutes';
  };

  useEffect(() => {
    fetch('https://avalanche.cash/api/avalanche/network', {})
      .then((res) => res.json())
      .then((response) => {
        setNetworkData(response);
        setNetworkLoading(false);
      })
      .catch((error) =>
        setNetworkData({
          nodes: [{ id: 'something went wrong', type: 'peer' }],
        })
      );
    fetch('https://avalanche.cash/api/avalanche/summary', {})
      .then((res) => res.json())
      .then((response) => {
        setNetworkTotals(response);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="network-ctn animate__animated animate__fadeInUp">
      <h3>eCash Avalanche Network Overview</h3>
      <div className="networkstat-ctn">
        <div className="networkstat">
          <span>{networktotals.nodeCount}</span>
          <span>Nodes</span>
        </div>
        <div className="networkstat">
          <span>
            {networktotals.totalStake === '...'
              ? '...'
              : parseFloat(networktotals.totalStake).toLocaleString()}
          </span>
          <span>XEC Staked</span>
        </div>
        <div className="networkstat">
          <span>{networktotals.proofCount}</span>
          <span>Peers</span>
        </div>
        {selectedNode && (
          <div className="nodecard-ctn">
            <div className="nodeCard">
              {selectedNode.type === 'node' && (
                <>
                  <div>Avalanche Node</div>
                  <div>Version: {selectedNode.version}</div>
                </>
              )}
              {selectedNode.type === 'peer' && (
                <>
                  <div>Avalanche Peer</div>
                  <div>Staked: {selectedNode.amount.toLocaleString()} XEC</div>
                </>
              )}
              {selectedNode.type === 'stake' && (
                <>
                  <div>Avalanche Stake</div>
                  <div>Amount: {selectedNode.amount.toLocaleString()} XEC</div>
                  <div>Age: {getDays(selectedNode.timeStamp)}</div>
                </>
              )}
              {selectedNode.type === 'ecash' && (
                <>
                  <div>The eCash Avalanche Network</div>
                  <div>
                    Total Staked:{' '}
                    {parseFloat(networktotals.totalStake).toLocaleString()} XEC
                  </div>
                  <div>Number of Nodes: {networktotals.nodeCount}</div>
                  <div>Number of Peers: {networktotals.proofCount}</div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {networkloading ? (
        <div className="loading-ctn">
          Loading Network Data <span className="dot dot1">.</span>
          <span className="dot dot2">.</span>
          <span className="dot dot3">.</span>
        </div>
      ) : (
        <div>
          <ForceGraph3D
            graphData={networkdata}
            ref={fgRef}
            // extraRenderers={extraRenderers}
            width={window.innerWidth * 0.7}
            height={600}
            backgroundColor={'#002b48'}
            onNodeHover={(node) => {
              if (node) {
                setSelectedNode(node);
              } else {
                setSelectedNode(null);
              }
            }}
            showNavInfo={true}
            nodeLabel={function () {
              return null;
            }}
            nodeVal={function (n) {
              if (n.type === 'node') {
                return 2;
              }
              if (n.type === 'peer') {
                let base = (3 * n.amount) / (4 * Math.PI);
                let exponent = 1 / 3;
                return Math.pow(base, exponent) / 10;
              }
              if (n.type === 'stake') {
                let base = n.amount / 100000000;
                return base;
              }
              if (n.type === 'ecash') {
                let base = 100;
                return base;
              }
            }}
            nodeColor={function (n) {
              if (n.type === 'node') {
                return '#0074c2';
              } else if (n.type === 'stake') {
                return '#fff';
              } else if (n.type === 'peer') {
                return '#FF21D0';
              } else return 'rgba(0,0,0,0)';
            }}
            linkWidth={1}
            onEngineStop={() => {
              if (!stopEngine) {
                fgRef.current.zoomToFit(1000, 50);
                setStopEngine(true);
              }
            }}
            cooldownTicks={100}
            nodeThreeObjectExtend={true}
            nodeThreeObject={(n) => {
              if (n.type === 'ecash') {
                const imgTexture = new THREE.TextureLoader().load(EcashLogo);
                const material = new THREE.SpriteMaterial({ map: imgTexture });
                const sprite = new THREE.Sprite(material);
                sprite.scale.set(30, 30);

                return sprite;
              } else return;
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Network;
