import Check from './images/check.png';
import DevIcon from './images/dev-icon.png';

const Roadmap = ({ roadmapdata }) => {
  const reverseData = [...roadmapdata].reverse();
  return (
    <>
      {reverseData.map(
        (item, index) =>
          !item.attributes.completed_date && (
            <>
              <div
                className="milestone-block"
                key={index}
                id={'milestone' + item.id}
              >
                <div
                  className="milestone-header"
                  style={{ backgroundColor: '#b600e7' }}
                >
                  <span>Milestone {item.id}</span>
                  {item.attributes.title}
                </div>
                <div
                  className="milestone-body"
                  style={{ backgroundColor: '#440056' }}
                >
                  <p>{item.attributes.text}</p>
                  <div
                    className="complete-date"
                    style={{ backgroundColor: '#b600e7' }}
                  >
                    Underway
                  </div>
                </div>
              </div>
              {item.attributes.sub_items && (
                <div className="sub-item-ctn">
                  <div
                    className="sub-item-line"
                    style={{ backgroundColor: '#510f6d' }}
                  />
                  {item.attributes.sub_items.map((sub_item, index) => (
                    <div className="sub-item" key={index}>
                      {sub_item.text}
                      {!sub_item.complete && (
                        <div className="sub-underway">
                          <div
                            style={{
                              marginRight: '10px',
                              display: 'inline-block',
                            }}
                          >
                            Underway
                          </div>
                        </div>
                      )}
                      <div className="sub-diff-ctn">
                        {sub_item.diffs.map((diff, index) => (
                          <>
                            <a
                              key={index}
                              className="sub-diff"
                              href={`https://reviews.bitcoinabc.org/${diff}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {diff}
                            </a>
                            <span>|</span>
                          </>
                        ))}
                      </div>
                      <div
                        className="check-circle"
                        style={
                          !sub_item.complete
                            ? { backgroundColor: '#b600e7' }
                            : null
                        }
                      >
                        <img
                          src={sub_item.complete ? Check : DevIcon}
                          alt="check"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )
      )}
      <div className="sub-item-ctn">
        <div className="sub-item-line" style={{ backgroundColor: '#510f6d' }} />
        {reverseData.map(
          (item) =>
            item.attributes.completed_date && (
              <>
                {item.attributes.sub_items.map(
                  (sub_item, index) =>
                    !sub_item.complete && (
                      <div className="sub-item" key={index}>
                        {sub_item.text}
                        <div className="sub-underway">
                          <div
                            style={{
                              marginRight: '10px',
                              display: 'inline-block',
                            }}
                          >
                            Underway
                          </div>
                          <div className="sub-diff-ctn">
                            {sub_item.diffs.map((diff, index) => (
                              <>
                                <a
                                  key={index}
                                  className="sub-diff"
                                  href={`https://reviews.bitcoinabc.org/${diff}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {diff}
                                </a>
                                <span>|</span>
                              </>
                            ))}
                          </div>
                        </div>
                        <div
                          className="check-circle"
                          style={{ backgroundColor: '#b600e7' }}
                        >
                          <img src={DevIcon} alt="check" />
                        </div>
                      </div>
                    )
                )}
              </>
            )
        )}
      </div>
      <div className="we-are-here-break">
        We are here<div className="purple-arrow2"></div>
      </div>
      {reverseData.map(
        (item, index) =>
          item.attributes.completed_date && (
            <>
              <div className="sub-item-ctn">
                <div className="sub-item-line" />
                {item.attributes.sub_items.map(
                  (sub_item, index) =>
                    sub_item.complete && (
                      <div className="sub-item" key={index}>
                        {sub_item.text}
                        <div className="sub-diff-ctn">
                          {sub_item.diffs.map((diff, index) => (
                            <>
                              <a
                                key={index}
                                className="sub-diff"
                                href={`https://reviews.bitcoinabc.org/${diff}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {diff}
                              </a>
                              <span>|</span>
                            </>
                          ))}
                        </div>
                        <div className="check-circle">
                          <img src={Check} alt="check" />
                        </div>
                      </div>
                    )
                )}
              </div>
              <div
                className="milestone-block"
                key={index}
                id={'milestone' + item.id}
                style={
                  item.attributes.completed_date === 'Incubating'
                    ? {
                        filter: 'hue-rotate(-104deg) saturate(0.84)',
                      }
                    : null
                }
              >
                <div className="milestone-header">
                  <span>Milestone {item.id}</span>
                  {item.attributes.title}
                </div>
                <div className="milestone-body">
                  <p>{item.attributes.text}</p>
                  <div className="complete-date">
                    {item.attributes.completed_date}
                  </div>
                  {item.attributes.diffs &&
                    item.attributes.diffs.map((diff, index) => (
                      <a
                        key={index}
                        className="diffs"
                        href={`https://reviews.bitcoinabc.org/${diff}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {diff}
                      </a>
                    ))}
                </div>
              </div>
            </>
          )
      )}
    </>
  );
};

export default Roadmap;
